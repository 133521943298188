<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('shift.label', 2)" />
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
    />
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import useLoadEntities from '@/hooks/api/loadEntities';
import useEntityFields from '@/hooks/api/fields';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';

export default {
  name: 'ShiftIndex',
  components: { EntityTable },
  setup() {
    const { shift: fields } = useEntityFields();
    const route = useRoute();
    const {
      entities,
      currentPage,
      filters,
      loadEntities,
      loadingEntities,
      resetFilters,
      sortBy,
      sortDirection,
      totalItems,
      updateFilters,
    } = useLoadEntities(
      '/shifts',
      {
        fields,
        sort: ['start', 'register.name'],
        direction: ['desc', 'asc'],
        defaultParams: {
          'register.store': route.params.storeId,
        },
      },
    );

    return {
      fields,
      entities,
      currentPage,
      filters,
      loadEntities,
      loadingEntities,
      resetFilters,
      sortBy,
      sortDirection,
      totalItems,
      updateFilters,
    };
  },
};
</script>
